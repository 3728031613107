import React from 'react';
import Home_Partners from '../Home_Partners/Home_Partners';
import { Title_page } from '../Function/Function';

const Partners = () => {
  return (
    <div>
      <Title_page title="Business Partner" />
        <Home_Partners/>
    </div>
  );
}

export default Partners;
