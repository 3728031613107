import React from 'react';
import Home_Contactus from '../Home_Contactus/Home_Contactus';
import { Title_page } from '../Function/Function';

const Contactus = () => {
  return (
    <div>
       <Title_page title="Contact Us" />
      <Home_Contactus/>
    </div>
  );
}

export default Contactus;
