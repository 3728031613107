import React from 'react';
import Home_Products from '../Home_Products/Home_Products';
import { Title_page } from '../Function/Function';

const Product = () => {
  return (
    <div>
      <Title_page title="Products" />
      <Home_Products/>
    </div>
  );
}

export default Product;
