import React from 'react';
import Home_Aboutus from '../Home_Aboutus/Home_Aboutus';
import { Title_page } from '../Function/Function';

const Aboutus = () => {
  return (
    <div>
      <Title_page title="About Us" />
      <Home_Aboutus />
    </div>
  );
};

export default Aboutus;
